import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from "axios";

Vue.config.productionTip = false
if (process.env.VUE_APP_BACKEND_ADDR) {
  axios.defaults.baseURL = process.env.VUE_APP_BACKEND_ADDR;
} else {
  axios.defaults.baseURL = "https://bot-02.danonetest.bizml.ru/api/";
}

// вставляет заголовки с токеном в каждый запрос
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  const tokenType = localStorage.getItem("tokenType");
  config.headers.Authorization = tokenType + " " + token;
  return config;
}, (error) => {
  return Promise.reject(error);
});

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
}
// выход из уч записи при получении ошибки 401 или др
// axios.interceptors.response.use(
//   (response) => {
//     if (response.status === 401 || response.status === 403) {
//       // если хоть раз приходит "неавторизован" - разлогинить юзера
//       store.dispatch("authorization/logout");
//       // отправляем на стр входа
//       router.push({ name: "Login" });
//     }
//     return response;
//   },
//   (error) => {
//     if (error.response && error.response.data) {

//       store.dispatch("authorization/logout");
//       // отправляем на стр входа
//       router.push({ name: "Login" });
//       return Promise.reject(error.response.data);
//     }
//     store.dispatch("authorization/logout");
//     // отправляем на стр входа
//     router.push({ name: "Login" });
//     return Promise.reject(error.message);
//   }
// );
axios.interceptors.response.use(
  (response) => {
    if (response.status === 401 || response.status === 403) {
      // если хоть раз приходит "неавторизован" - разлогинить юзера
      store.dispatch("authorization/logout");
      // отправляем на стр входа
      router.push({ name: "Login" });
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      if (error.response.status === 401 || error.response.status === 403) {
        // если хоть раз приходит "неавторизован" - разлогинить юзера
        store.dispatch("authorization/logout");
        // отправляем на стр входа
        router.push({ name: "Login" });
      }
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
