const state = () => ({
  // стрелка поднята
  arrowFollowGlobal: false,
  // нужна перерисовка
  needRedraw: false,
  redrawBlock_id: "",

  // инфа о блоке инициаторе
  block_id: "",
  element_id: "",
  type: "",
  col_position: "",
  // обратная стрелка
  backward: false,

  // ховер стрелки/кнопки или её связанного блока
  hoveredArrowId: '',
  hoveredBlockId: ''
});

const mutations = {
  change(state, val) {
    state.arrowFollowGlobal = val;
  },
  save_block_info(state, payload) {
    state.block_id = payload.block_id;
    state.element_id = payload.element_id;
    state.type = payload.type;
    state.col_position = payload.col_position;
    state.backward = payload.backward
  },
  change_redraw_status(state, payload) {
    state.needRedraw = payload.state;
    state.redrawBlock_id = payload.id;
  },
  saveHoveredArrow(state, payload) {
    state.hoveredArrowId = payload.hoveredArrowId;
    state.hoveredBlockId = payload.hoveredBlockId;
  },
};
const actions = {
  activateArrow({ commit }) {
    commit("change", true);
  },
  disableArrow({ commit }) {
    commit("change", false);
  },
  saveBlockInfo({ commit }, payload) {
    commit("save_block_info", payload);
  },
  redrawComplete({ commit }) {
    commit("change_redraw_status", {
      id: '',
      state: false,
    });
  },
  redrawNeed({ commit }, payload) {
    commit("change_redraw_status", {
      id: payload,
      state: true,
    });
  },
  saveHoveredArrow({ commit }, payload) {
    commit("saveHoveredArrow", {
      hoveredArrowId: payload.hoveredArrowId,
      hoveredBlockId: payload.hoveredBlockId
    });
  },
};
const getters = {
  getArrowGlobalState: (state) => state.arrowFollowGlobal,
  getInitiatorInfo: (state) => {
    return {
      block_id: state.block_id,
      element_id: state.element_id,
      type: state.type,
      col_position: state.col_position,
      backward: state.backward
    };
  },
  ifRedrawNeed: (state) => {
    return state.needRedraw;
  },
  getHoveredArrow: (state) => {
    return { hoveredArrowId: state.hoveredArrowId, hoveredBtnId: state.hoveredBtnId, hoveredBlockId: state.hoveredBlockId }
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
