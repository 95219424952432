<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      dark
      height="70"
      class="header"
      v-show="$route.name !== 'Login'"
    >
      <router-link class="d-flex align-center" :to="{ name: 'Knowledge' }">
        <v-img
          alt="Danone Logo"
          class="shrink mr-2"
          contain
          src="@/assets/header/Nutricia-logo.png"
          width="119"
        />
      </router-link>

      <v-spacer></v-spacer>
      <!-- Рассылка -->
      <v-btn
        text
        color="rgb(0,0,0,0.58)"
        class="link"
        :to="{ name: 'Mailing' }"
      >
        <span>Рассылка</span>
      </v-btn>
      <!-- Статистика -->
      <v-btn
        text
        class="link"
        color="rgb(0,0,0,0.58)"
        :to="{ name: 'Statistics' }"
      >
        <span>Статистика</span>
      </v-btn>
      <!-- Каналы -->
      <v-btn
        text
        class="link"
        color="rgb(0,0,0,0.58)"
        :to="{ name: 'Сhannels' }"
      >
        <span>Каналы</span>
      </v-btn>
      <!-- База знаний -->
      <v-btn
        text
        class="link"
        color="rgb(0,0,0,0.58)"
        :to="{ name: 'Knowledge' }"
      >
        <span>База знаний</span>
      </v-btn>
      <!-- выход -->
      <v-btn
        v-show="$route.name !== 'Login'"
        text
        color="black"
        @click="isLoggedIn ? logout() : $router.push({ name: 'Login' })"
      >
        <span>{{ isLoggedIn ? "Выйти" : "Войти" }}</span>
      </v-btn>
    </v-app-bar>

    <v-main class="main">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  computed: {
    isLoggedIn() {
      return this.$store.getters["authorization/isLoggedIn"];
    },
  },
  methods: {
    logout() {
      // выход из уч. записи
      this.$store
        .dispatch("authorization/logout")
        .then(() => this.$router.push({ name: "Login" }))
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
// оверфлоу для страницы БЗ
html.overflow {
  overflow: scroll;
}
@import url("~@/styles/normalize.scss");
.v-overlay {
  margin-top: 70px;
  &__scrim {
    // opacity: 0 !important;
    background-color: rgba(108, 108, 108, 0.15) !important;
  }
}
.header {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 1px 5px rgba(0, 0, 0, 0.12) !important;
  z-index: 200 !important;
  .customers-btn {
    margin: 0 8px;
    margin-right: 64px;
  }
}
.link {
  &.v-btn--active {
    color: #006fc0 !important;
  }
  &::before {
    background-color: transparent !important;
  }
  &.v-btn--active::before {
    background-color: transparent !important;
  }
  &:hover.v-btn--active {
    color: #4b99d2 !important;
  }
  &:hover {
    color: #4b99d2 !important;
    background: transparent;
  }
}

.v-input--switch__thumb {
  color: white !important;
}
// для фиксации заголовков таблицы, еще необходимо добавить class sticky-header заголовкам
.v-data-table .v-data-table__wrapper {
  overflow: unset !important;
  overflow-x: unset !important ;
  overflow-y: unset !important ;
}
body,
.main {
  background: #f5f5f5;
}
// .main{
//   padding: 0 !important;
// }
button {
  outline: none;
}
button:focus {
  outline: none;
}
</style>
