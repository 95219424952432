import axios from "axios";
// Аутентификация
const state = () => ({
  // статус авторизации
  authStatus: "",
  // токен
  token: localStorage.getItem("token") || "",
  // тип токена
  tokenType: localStorage.getItem("tokenType") || "",
  // role admin user
  role: localStorage.getItem('role') || ""
});

const mutations = {
  auth_request(state) {
    state.authStatus = "loading";
  },
  auth_success(state, payload) {
    state.authStatus = "success";
    state.token = payload.token;
    state.tokenType = payload.tokenType;
    state.role = payload.role
  },
  auth_error(state) {
    state.authStatus = "error";
  },
  logout(state) {
    state.authStatus = "";
    state.token = "";
    state.tokenType = "";
    state.role = ''
  },
};
const actions = {
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit("auth_request");
      axios
        .post(`/auth/login`, user)
        .then((resp) => {
          // достаем инфу о токене
          const token = resp.data.access_token;
          const tokenType = resp.data.token_type;
          const role = resp.data.role;
          // кладем в хранилище
          localStorage.setItem("token", token);
          localStorage.setItem("tokenType", tokenType);
          localStorage.setItem('role', role)
          commit("auth_success", {
            token: token,
            tokenType: tokenType,
            role: role
          });
          resolve(resp);
        })
        .catch((err) => {
          commit("auth_error");
          localStorage.removeItem("token");
          reject(err);
        });
    });
  },
  logout({ commit }) {
    return new Promise((resolve) => {
      commit("logout");
      localStorage.removeItem("token");
      delete axios.defaults.headers.common["Authorization"];
      resolve();
    });
  },
};
const getters = {
  isLoggedIn: (state) => !!state.token,
  authStatus: (state) => state.authStatus,
  getRole: (state) => state.role
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
