import Vue from "vue";
import Vuex from "vuex";

import authorization from "./modules/authorization";

import arrow from "./modules/arrow";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authorization,
    arrow
  },
});
